.officer-heading {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.admin-photo {
  width: 100%;
  min-height: 300px;
  max-height: 300px;
}

.admin-card {
  height: 665px;
  width: 75%;
}

.officer-title-odd {
  color: rgb(2, 132, 199);
  font-size: 2rem;
  padding-bottom: 10px;
  text-align: right;
  border-bottom: 1px solid rgb(2, 132, 199);
}
.officer-title-even {
  color: rgb(2, 132, 199);
  font-size: 2rem;
  padding-bottom: 10px;
  text-align: left;
  border-bottom: 1px solid rgb(2, 132, 199);
}

.officer-photo-col-odd {
  display: flex;
  justify-content: right;
}

.officer-photo-col-even {
  display: flex;
  justify-content: left;
}

.officer-name-even,
.officer-name-odd {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
}

.officer-name-even {
  text-align: right;
}

.officer-desc {
  margin-bottom: 10px;
}

.officer-photo {
  max-height: 300px;
  min-height: 300px;
  max-width: 225px;
  min-width: 225px;
  border: 1px solid black;
}

.member-cluster {
  text-align: center;
}

.member-cluster p {
  margin-bottom: 0;
}

.member-cluster span {
  color: #15803d;
  font-weight: 600;
}

.memorial-header {
  text-align: center;
  background-image: linear-gradient(90deg, #116730, #195d32);
  font-weight: bold;
  border-radius: 5px;
  color: white;
  padding: 10px 10px;
}

.memorial-cluster {
  background-image: linear-gradient(180deg, #ccad23, #c5aa20);
  padding-top: 0.25rem;
  background-color: #15803d;
  background-size: 100%;
  font-weight: 600;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

@media screen and (max-width: 1350) {
  .admin-card {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .officer-photo-col-odd,
  .officer-photo-col-even {
    justify-content: center;
  }

  .admin-card {
    width: 50%;
  }
}
