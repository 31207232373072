ul.custom-navbar {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  background-color: #064e3b;
  color: white;
}

ul.custom-navbar li {
  display: inline-block;
  padding: 1rem;
}

ul.custom-navbar svg {
  padding: 0.25rem;
}

ul.custom-navbar li.align-right {
  position: absolute;
  margin-left: auto;
  right: 0;
}

ul.custom-navbar li:hover {
  background-color: #047857;
  cursor: pointer;
}

ul.custom-navbar .active {
  background-color: #15803d;
}

ul.custom-navbar li.hamburger-menu-close,
ul.custom-navbar li.hamburger-menu-open {
  display: none;
}

.custom-navbar-header {
  text-align: center;
  background-color: rgb(6, 95, 70);
  margin: 0;
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
}

.custom-navbar-header h1 {
  color: white;
  letter-spacing: 0.5rem;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}

.custom-navbar-header img {
  justify-self: left;
  box-sizing: border-box;
  height: 65px;
}

@media screen and (max-width: 750px) {
  .custom-navbar-header img {
    display: none;
  }

  ul.custom-navbar li:not(.active) {
    display: none;
  }

  ul.custom-navbar li.hamburger-menu-close {
    display: inline;
    float: right;
  }
  ul.custom-navbar li.hamburger-menu-open {
    display: list-item;
    position: initial;
  }

  ul.custom-navbar li.show-true {
    display: list-item;
  }
}
