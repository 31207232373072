.odd {
  background-color: blue;
}

.response-card {
  background-color: #1e293b;
  color: white;
  height: 550px;
}

.ambulance-photo {
  display: flex;
  justify-content: right;
}

.ambulance-photo img {
  width: 50%;
}

@media screen and (max-width: 850px) {
  .ambulance-photo img {
    width: 100%;
  }
}
