div.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  background-color: #064e3b;
  color: white;
  width: 100%;
  text-align: center;
}

div.footer div {
  text-align: center;
  align-self: center;
}

div.footer div p {
  margin: 3px;
}
